import React from 'react';
import './App.css';
import { Gitgraph } from '@gitgraph/react';
import django from './django.json';

const options = {orientation: 'horizontal'};

function App() {
  return (
    <Gitgraph options={options}>
    {(gitgraph) => {
      gitgraph.import(django);
    }}
    </Gitgraph>
  );
}

export default App;
